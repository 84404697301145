import React, { useState } from "react";
import { Navbar } from "../../components/Navbar";
import { RegularButtons } from "../../components/RegularButtons";
import { Link } from "react-router-dom";
import { ContactSlider } from "../../components/ContactSlider";
import './style.scss'
import { NewsletterDetails } from "../../Validations/UserValidation"
import { EmailValidation } from "../../Validations/UserValidation"
import { Footer } from "../../components/Footer";
import { postCall } from "../../api/requests";
import Swal from 'sweetalert2'
import endpoints from "../../api/endpoints";
import video from "../../images/video.png"
import { LoadingButton } from '@mui/lab';
import { useFormik } from "formik";
import ReadyImg from "../../images/ready.png"
import digital from "../../images/Digital.png"
import Star from "../../images/Star.png"
import { Newsletter } from "../../components/Newsletter";





export const AboutUs = (): JSX.Element => {


    const [isLoading, setIsLoading] = useState(false)
    const initialValues = {
        email: ""
    }


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: EmailValidation,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: async (values: NewsletterDetails) => {
            setIsLoading(true)
            postCall(endpoints.newsletter, values).then((res) => {
                console.log({ res })
                Swal.fire({
                    title: 'Subscribed',
                    text: 'Thank you, we will contact you soon',
                    icon: 'success',
                    confirmButtonText: 'Close'
                })
                setIsLoading(false)
                console.log(res)
            }).catch((err) => {
                Swal.fire({
                    title: 'Oop!',
                    text: 'Something went wrong, try again',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })
                console.log({ err })
                setIsLoading(false)
            })
        }
    })

    const data = [
        {
            title: 'Our Story',
            img: Star,
            text: 'At Devos, we embody ambition in everything we do. Our journey began with a determination to revolutionize the payment industry. Recognizing the limitations of existing providers, we set out to build a robust and modern financial technology platform from scratch. Today, we empower global businesses with comprehensive end-to-end payment capabilities, data enhancements, and financial products, all integrated into a single, cutting-edge solution.'
        },
        {
            title: 'Our Mission',
            img: Star,
            text: 'Our mission at Devos is to empower businesses and organizations to thrive in the digital age. We combine our technical expertise, innovative mindset, and strategic insights to create transformative solutions that drive growth, efficiency, and sustainable success.'
        },
        {
            title: 'Our Vision',
            img: Star,
            text: 'Our vision is to be the leading provider of integrated digital solutions for businesses worldwide. We strive to revolutionize industries by leveraging advanced technology, data insights, and seamless experiences. We envision a future where organizations embrace digital innovation to unlock their full potential and achieve sustainable success.'
        },
    ]
    return (
        <div className="aboutUs-page">
            <Navbar
                className="navbar-instance"
                property1="contact-sales"
                regularButtonsButtonTypePrimaryClassName="design-component-instance-node-2"
                to="/"
            />
            <div className="max-width">

            <header className="header max-width">
                <div className="frame-25">
                    <div className="frame-15">
                        <h3 className="h3">About Us</h3>
                        <h1 className="h-1" data-aos="fade-down">
                            <span className="span"> Nigeria’s Payment Operating System.</span>
                            {/* <span className={`text-wrapper-7 ${changeTextColor && 'yellow-text'}`}> <Type /> </span> */}
                        </h1>
                        <p className="title-7" data-aos="fade-up">
                            Transforming Business Landscape: Streamline Transactions, Boost Revenue, and Empower Micro-Merchants with Secure and Comprehensive Digitized Payment Solutions.
                        </p>
                    </div>
                    <Link to={'/contact-sales'}>
                        <div className="video-highlight">
                            Watch a 2 min DevOS Highlight
                        </div>
                    </Link>
                </div>
                <div className="overlap-wrapper" data-aos="zoom-in">
                    <ContactSlider />
                </div>
            </header>
            </div>
            <div className="marchant-section">
                <div className="marchant-inner max-width">

                    <div className="section">
                        <h3>Our Story, Mission, And Vision</h3>
                    </div>
                    <div className="frame-2">

                        <div data-aos="fade-down">
                            <h1>Empowering Digital Transformation.</h1>
                        </div>
                    </div>
                </div>
                <div className="digital-section max-width">
                    <div data-aos="fade-down" className="digital-section-left">

                        <img src={digital} alt=" digital" />

                    </div>

                    <div className="digi-container">
                        {data.map((item, index) => (


                            <div data-aos="fade-down" className="digital-section-right" key={index}>
                                <div>

                                    <div className="icons">
                                        <img src={item.img} alt="icon" />
                                    </div>
                                </div>


                                <div className="digital-text">
                                    <h1>{item.title}</h1>
                                    <p>{item.text}</p>
                                </div>


                            </div>
                        ))

                        }
                    </div>



                </div>

            </div>
            <div className="vendor-section max-width">

                <div className="section">
                    <h3>How Do We Work?</h3>
                </div>
                <div className="frame-3">

                    <div data-aos="fade-down">
                        <h1>Company Culture and Events</h1>
                    </div>
                    <div>
                        <p data-aos="fade-down">At Devos, our work culture is fueled by collaboration, creativity, and continuous improvement. We foster an environment where ideas are encouraged, and expertise is valued. </p>
                    </div>

                </div>
                <div className="video" data-aos="zoom-in">
                    <img src={video} alt="" />
                </div>
            </div>
            <div className="vendor-section max-width">

                <div className="section">
                    <h3>Our Team</h3>
                </div>
                <div className="frame-3">

                    <div data-aos="fade-down">
                        <h1>Meet Our Diverse and Talented Teams</h1>
                    </div>
                    <div>
                        <p data-aos="fade-down">With a wide range of expertise spanning technology, design, strategy, and beyond, our teams work collaboratively to create transformative solutions. We leverage the collective knowledge and skills of our professionals to address the unique challenges faced by our clients. </p>
                    </div>

                </div>

            </div>
            <Newsletter/>
            {/* <div className="CT-as max-width">
                <div className="overlap-group" >
                    <div className="frame-wrapper">
                        <div className="frame-9">
                            <div className="frame-10" data-aos="fade-down">
                                <p className="p">Stay in the know with our newsletter</p>
                            </div>
                            <div className="test">
                                <form className="regular-buttons-4" onSubmit={(e) => {
                                    e.preventDefault()
                                    formik.handleSubmit()
                                }
                                }>
                                    <input
                                        name="newsletterEmail"
                                        type="email"
                                        onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                        className="text-wrapper-6" placeholder="Drop your email" />
                                    <LoadingButton type="submit" variant="outlined" className="btn" loading={isLoading}>
                                        Subscribe
                                    </LoadingButton>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="img-ready">

                        <img
                            className="image phone1"
                            alt="devos terminal"
                            src={ReadyImg}
                            data-aos="fade-down"
                        />
                    </div>
                </div>
            </div> */}
            <Footer />
        </div>

    );
}