import PropTypes from "prop-types";
import React from "react";
import "./style.scss";

interface Props {
  className: any;
  homeClassName: any;
  home: string;
}

export const IconlyRegular = ({
  className,
  homeClassName,
  home = "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home@2x.png",
}: Props): JSX.Element => {
  return (
    <div className={`iconly-regular ${className}`}>
      <img className={`home ${homeClassName}`} alt="Home" src={home} />
    </div>
  );
};

IconlyRegular.propTypes = {
  home: PropTypes.string,
};
