import "./style.scss";
import Logo from "../../images/full-logo.png"
import EmailIcon from '@mui/icons-material/Email';


export const Footer = (): JSX.Element => {
  return (
    <div className="footer max-width">

      <div className="footer-white">
        <div className="overlap">
          <div className="footer-white-2">
            <div className="frame-5">
              <div className="foot">
                <div className="div-3">
                  <img
                    className="img"
                    alt="Title"
                    src={Logo}
                  />

                </div>
                <div className="div-4">

                  <p className="lorem-ipsum-dolor-si">
                    DevOS empowers you to accept digital payments with ease, enhance your operational efficiency, and
                    tap into a larger customer base.
                  </p>
                </div>
              </div>
              <div className="div-4">
                <div className="text-wrapper-2">Solutions</div>
                <div className="frame-6">
                  <div className="text-wrapper-3">Affordable Payment Terminals</div>
                  <div className="text-wrapper-4">Seamless Transaction Processing</div>
                </div>
              </div>
              <div className="div-4">
                <div className="text-wrapper-2">Contact</div>
                <div className="frame-6">
                  <div className="text-wrapper-3 emai-icon"> <EmailIcon /> &nbsp; sales@getdevos.com</div>

                </div>
              </div>
              {/* <div className="div-4">
              <div className="text-wrapper-2">Company</div>
              <div className="frame-6">
                <a className="text-wrapper-3" href="/">About Us</a>
                <a className="text-wrapper-4" href="/">Benefits</a>
                <a className="text-wrapper-4" href="/">Team</a>
                <a className="text-wrapper-4" href="/">Careers</a>
              </div>
            </div>
            <div className="learning">
              <a className="text-wrapper-2" href="/">Resources</a>
              <a className="text-wrapper-5" href="/">Faqs</a>
              <a className="text-wrapper-5" href="/">Press And Media</a>
              <a className="text-wrapper-5" href="/">Contact Us</a>
            </div> */}
            </div>
            <div className="divider"></div>
            {/* <img
            className="divider"
            alt="Divider"
            src="https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/divider.svg"
          /> */}
            <div className="frame-7">
              <p className="element-APCOM-all-ri">Copyright © 2023 DevOS. All rights reserved.</p>
              <div className="frame-8">
                <a className="text-wrapper-3" href="/terms-conditions" target="_blank">Terms &amp; Conditions</a>
                <a className="text-wrapper-3" href="/privacy-policy" target="_blank">Privacy Policy</a>
              </div>
              {/* <div className="social">
                <div className="twitter-icon">
                  <img
                    className="twitter"
                    alt="Twitter"
                    src="https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/twitter.svg"
                  />
                </div>
                <div className="linkedin-icon">
                  <img
                    className="group"
                    alt="Group"
                    src="https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/group-14@2x.png"
                  />
                </div>
                <div className="facebook-icon">
                  <img
                    className="group"
                    alt="Shape"
                    src="https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/shape.svg"
                  />
                </div>
              </div> */}
            </div>
          </div>
          {/* <img
              className="bg"
              alt="Bg"
              src="https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/bg.svg"
            /> */}

        </div>
      </div>
    </div>
  );
};

