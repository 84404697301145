import React, { useEffect, useState } from "react"
import { Navbar } from "../../components/Navbar"
import { Link } from "react-router-dom"
import "./style.scss"
import { SliderCar } from "../../components/Slider"
import { Slider2 } from "../../components/Slider2/Slider2"
import TMS from "../../images/TMSLappy.png"
import { Footer } from "../../components/Footer"
import ReadyImg from "../../images/ready.png"
import { postCall } from "../../api/requests";
import endpoints from "../../api/endpoints";
import { useFormik } from "formik";
import { NewsletterDetails } from "../../Validations/UserValidation"
import { EmailValidation } from "../../Validations/UserValidation"
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2'
import Type from "./Type"
import { Newsletter } from "../../components/Newsletter"



export const LandingPagev3 = (): JSX.Element => {
  const [changeTextColor, setChangeTextColor] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [index, setIndex] = useState(0)


  const initialValues = {
    email: ""
  }

  const changingText = ['affordable phone terminals.', 'digitized payment solutions.', 'same day settlement.']

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: EmailValidation,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values: NewsletterDetails) => {
      setIsLoading(true)
      postCall(endpoints.newsletter, values).then((res) => {
        console.log({ res })
        Swal.fire({
          title: 'Subscribed',
          text: 'Thank you, we will contact you soon',
          icon: 'success',
          confirmButtonText: 'Close'
        })
        setIsLoading(false)
        console.log(res)
      }).catch((err) => {
        Swal.fire({
          title: 'Oop!',
          text: 'Something went wrong, try again',
          icon: 'error',
          confirmButtonText: 'Close'
        })
        console.log({ err })
        setIsLoading(false)
      })
    }
  })

  useEffect(() => {
    const clear = setInterval(() => {
      const i = index == 2 ? 0 : index + 1
      setIndex(i)
    }, 3000)
    return () => clearInterval(clear)
  }, [changeTextColor])



  useEffect(() => {
    const clear = setInterval(() => {
      setChangeTextColor(!changeTextColor)
    }, 23000)
    return () => clearInterval(clear)
  }, [changeTextColor])

  return (
    <div className="landing-page3">
      <Navbar
        className="navbar-instance"
        property1="contact-sales"
        regularButtonsButtonTypePrimaryClassName="design-component-instance-node-2"
        to="/"
      />
      <header className="header max-width">
        <div className="frame-25">
          <div className="frame-15">
            <h1 className="h-1" data-aos="fade-down">
              <div className="span">
                Empowering micro-merchants with <span className={`text-wrapper-7 `}> <Type /></span>
              </div>
            </h1>
            <p className="title-7" data-aos="fade-up">
              Streamline transactions, boost revenue, and empower micro merchants with secure and digitized smart feature phone terminals.
            </p>
          </div>
          <Link to={'/contact-sales'}>
            <button className="ContactSales-btn">Contact Sales</button>
          </Link>
        </div>
        <div className="overlap-wrapper" data-aos="zoom-in">
          <SliderCar />
        </div>
      </header>
      <div className="marchant-section">
        <div className="max-width">
          <div className="marchant-inner">
            <div className="section">
              <h2>All the needs of your micro-merchant in one terminal</h2>
            </div>
            <div className="frame-2">
              <div data-aos="fade-down">
                <h1 data-aos="fade-down">Pay suppliers, manage inventory, and receive payment all in one place.</h1>
              </div>
            </div>
          </div>
          <Slider2 />
        </div>
      </div>
      <div className="vendor-section max-width">
        <div className="section">
          <h2>Everything you need to manage deployed devices remotely.</h2>
        </div>
        <div className="frame-3">
          <div data-aos="fade-down">
            <h1 data-aos="fade-down">Send push notifications, remotely lock & unlock terminals and so much more!</h1>
          </div>
        </div>
        <div className="video" data-aos="zoom-in">
          <img src={TMS} alt="" />
        </div>
      </div>
      <Newsletter/>
      {/* <div className="CT-as max-width">
        <div className="overlap-group" >
          <div className="frame-wrapper">
            <div className="frame-9">
              <div className="frame-10" data-aos="fade-down">
                <p className="p">Stay in the know with our newsletter</p>
              </div>
              <div className="test">
                <form className="regular-buttons-4" onSubmit={(e) => {
                  e.preventDefault()
                  formik.handleSubmit()
                }
                }>
                  <input
                    name="newsletterEmail"
                    type="email"
                    onChange={(e) => formik.setFieldValue('email', e.target.value)}
                    className="text-wrapper-6" placeholder="Drop your email" />
                  <LoadingButton type="submit" variant="outlined" className="btn" loading={isLoading}>
                    Subscribe
                  </LoadingButton>
                </form>
              </div>
            </div>
          </div>
          <div className="img-ready">
            <img
              className="image phone1"
              alt="devos terminal"
              src={ReadyImg}
              data-aos="fade-down"
            />
          </div>
        </div>
      </div> */}
      <Footer />
    </div >
  )
}