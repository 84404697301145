import Typewriter from 'typewriter-effect';

function Type() {
    return (
        <Typewriter
            options={{
                strings: ['digitized payment solutions.', 'affordable phone terminals.', 'same day settlement.'],
                autoStart: true,
                loop: true,
                deleteSpeed:150,
            }}
        />
    )
}
export default Type;