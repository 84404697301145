import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const IconlySharpBoldArrowLeft21 = ({ color = "black", className }: Props): JSX.Element => {
  return (
    <svg
      className={`iconly-sharp-bold-arrow-left-2-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M15.4999 20.414L7.08594 12L15.4999 3.586L16.9139 5L9.91394 12L16.9139 19L15.4999 20.414Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

IconlySharpBoldArrowLeft21.propTypes = {
  color: PropTypes.string,
};
