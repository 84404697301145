import React from "react";
import { Navbar } from "../../components/Navbar";
import "./style.scss";
import { Footer } from "../../components/Footer";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import Checkbox from '@mui/material/Checkbox';
import PhoneIcon from '@mui/icons-material/Phone';
import { postCall } from "../../api/requests";
import endpoints from "../../api/endpoints";
import { UserValidation } from "../../Validations";
import { useFormik } from "formik";
import { PersonDetails } from "../../Validations/UserValidation";
import { ContactSlider } from "../../components/ContactSlider";
import Swal from 'sweetalert2'
import { LoadingButton } from "@mui/lab";





export const ContactSales = (): JSX.Element => {

  // const [userDetails, setUserDetails] = React.useState({

  // });

  const [checked, setChecked] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  // function handleOnChange(e: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | any) {
  //   const { name, value } = e.target
  //   console.log({ name, value })
  //   // setUserDetails({
  //   //   ...userDetails,
  //   //   [name]: value
  //   // });
  //   formik.setFieldValue(name, value)
  // }
  const initialValues = {
    firstName: "",
    lastName: "",
    businessEmail: "",
    businessPhone: "",
    businessName: "",
    jobTitle: "",
    comment: "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: UserValidation,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values: PersonDetails) => {

      handleSubmit(values)
    }
  })

  const handleSubmit = (values: PersonDetails) => {

    if (!checked) {
      Swal.fire({
        position: 'top',
        icon: 'warning',
        text: 'You have to accept the privacy policy',
        iconColor: "#FFF200",
        showConfirmButton: false,
        timer: 2000,
        toast: true,
      })
      return
    }
    setIsLoading(true)
    postCall(endpoints.contactSales, values).then((res) => {
      if(res.status===200){

        Swal.fire({
          title: 'Submitted',
          text: 'Thank you, we will contact you soon',
          icon: 'success',
          confirmButtonText: 'Close',
          confirmButtonColor: "#0151fd",
          customClass:{
            confirmButton: 'confirmButtonClass'
          }
        })
      }else{
        Swal.fire({
          title: 'Thank you',
          text: 'We already have your company details',
          confirmButtonText: 'Close',
          confirmButtonColor: "#0151fd",
          // buttonsStyling: false,
          customClass:{
            confirmButton: 'confirmButtonClass'
          }
        })
      }
      setIsLoading(false)
      console.log(res)
    }).catch((err) => {
      Swal.fire({
        title: 'Oop!',
        text: 'Something went wrong, try again',
        icon: 'error',
        confirmButtonText: 'Close',
        customClass:{
          confirmButton: 'confirmButtonClass'
        }
      })
      console.log({ err })
      setIsLoading(false)
    })
  }


  return (

    <div className="sales-page">
      <Navbar
        className="navbar-instance"
        property1="contact-sales"
        regularButtonsButtonTypePrimaryClassName="design-component-instance-node-2"
        to="/"
      />
      <div className="form-section max-width">

        <div className="form-section-left">
          <p className="contactSales">Contact Sales</p>
          <h1 className="h-1">Talk to our Sales team</h1>
          <form className="main-form" onSubmit={(e) => e.preventDefault()}>

            <FormControl className="input" sx={{ m: 0, pl: 0, pb: 2, width: '65ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">First Name</InputLabel>
              <OutlinedInput
                id="outlined-adornment-firstname"
                type='text'
                name="firstName"
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <PersonIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="First Name"
              />
              <div className="errorMessage">{formik.errors.firstName}</div>
            </FormControl>

            <FormControl className="input" sx={{ m: 0, pl: 0, pb: 2, width: '65ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-lastname">Last Name</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                name="lastName"

                type='text'
                // onChange={handleOnChange}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // onTouchEnd={formik.touched}


                endAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <PersonIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Last Name"
              />
              <div className="errorMessage">{formik.errors.lastName}</div>
            </FormControl>

            <FormControl className="input" sx={{ m: 0, pl: 0, pb: 2, width: '65ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-businessName">Business Email</InputLabel>

              <OutlinedInput
                id="outlined-adornment-businessName"
                name="businessEmail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Business Email"
                className={formik.errors.businessEmail}

                endAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      edge="end"
                    >
                      <MailIcon />
                    </IconButton>
                  </InputAdornment>
                }

              />
              <div className="errorMessage">{formik.errors.businessEmail}</div>
            </FormControl>

            <FormControl className="input" sx={{ m: 0, pl: 0, pb: 2, width: '65ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-businessEmail">Phone number</InputLabel>
              <OutlinedInput
                id="outlined-adornment-businessEmail"
                name="businessPhone"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={formik.errors.businessPhone}

                endAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      edge="end"
                    >
                      <PhoneIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Phone Number"
              />
              <div className="errorMessage">{formik.errors.businessPhone}</div>
            </FormControl>

            <FormControl className="input" sx={{ m: 0, pl: 0, pb: 2, width: '65ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-businessName">Business Name</InputLabel>

              <OutlinedInput
                id="outlined-adornment-businessName"
                name="businessName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Business Name"
                className={formik.errors.businessEmail}

                endAdornment={
                  <InputAdornment position="start">

                  </InputAdornment>
                }

              />
              <div className="errorMessage">{formik.errors.businessName}</div>
            </FormControl>




            <FormControl className="input" sx={{ m: 0, pl: 0, pb: 1, width: '65ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-jobTitle">Job Title</InputLabel>
              <OutlinedInput
                id="outlined-adornment-jobTitle"
                type="text"
                name="jobTitle"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={formik.errors.jobTitle}
                label="Job Title"
              />
              <div className="errorMessage">{formik.errors.jobTitle}</div>
            </FormControl>

            {/* <FormControl className="input" sx={{ m: 0, pl: 0, pb: 2, width: '35ch', height:'50ch' }} variant="outlined"> */}
            <div >
              <label className="label"> Description</label>
              <textarea className="text-area" placeholder="Description" name="comment" id=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
              </textarea>
              <div className="errorMessage">{formik.errors.comment}</div>
            </div>
            {/* </FormControl> */}

            <div className="checkbox">
              <div>
                <Checkbox
                  // type="checkbox" 
                  name="toggle"
                  checked={checked}
                  onChange={handleChange}
                  sx={{ m: 0, pl: 0, pb: 2, '& .MuiSvgIcon-root': { fontSize: 30 } }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div>
                <p>I Confirm that I have read DevOS's <a href="/privacy-policy" target="_blank">Privacy Policy</a> and I agree to the use of my data in line therewith</p>
              </div>
            </div>

            <LoadingButton className="btn-contact" type="submit" onClick={() => formik.handleSubmit()} loading={isLoading}>Submit</LoadingButton>
          </form>
        </div>
        <div className="form-section-right">
          <ContactSlider />
        </div>
      </div>
      <Footer />
    </div>
  );
};
