
import React, {useState} from "react"
import ReadyImg from "../../images/ready.png"
import { postCall } from "../../api/requests";
import endpoints from "../../api/endpoints";
import { useFormik } from "formik";
import { NewsletterDetails } from "../../Validations/UserValidation"
import { EmailValidation } from "../../Validations/UserValidation"
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2'
import './style.scss'


export const Newsletter = ()=> {
    const [isLoading, setIsLoading] = useState(false)
    const initialValues = {
        email: ""
      }
      const formik = useFormik({
        initialValues: initialValues,
        validationSchema: EmailValidation,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: async (values: NewsletterDetails) => {
          setIsLoading(true)
          postCall(endpoints.newsletter, values).then((res) => {
            console.log({ res })
            Swal.fire({
              title: 'Subscribed',
              text: 'Thank you, we will contact you soon',
              icon: 'success',
              confirmButtonText: 'Close'
            })
            setIsLoading(false)
            console.log(res)
          }).catch((err) => {
            Swal.fire({
              title: 'Oop!',
              text: 'Something went wrong, try again',
              icon: 'error',
              confirmButtonText: 'Close'
            })
            console.log({ err })
            setIsLoading(false)
          })
        }
      })

return(
    <div className="CT-as max-width">
    <div className="overlap-group" >
      <div className="frame-wrapper">
        <div className="frame-9">
          <div className="frame-10" data-aos="fade-down">
            <p className="p">Stay in the know with our newsletter</p>
          </div>
          <div className="test">
            <form className="regular-buttons-4" onSubmit={(e) => {
              e.preventDefault()
              formik.handleSubmit()
            }
            }>
              <input
                name="newsletterEmail"
                type="email"
                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                className="text-wrapper-6" placeholder="Drop your email" />
              <LoadingButton type="submit" variant="outlined" className="btn" loading={isLoading}>
                Subscribe
              </LoadingButton>
            </form>
          </div>
        </div>
      </div>
      <div className="img-ready">

        <img
          className="image phone1"
          alt="devos terminal"
          src={ReadyImg}
          data-aos="fade-down"
        />
      </div>
    </div>
  </div>
)
}