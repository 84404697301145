import React, { useRef } from "react";
import Slider from "react-slick";
import { IconlySharpBoldArrowRight21 } from "../../icons/IconlySharpBoldArrowRight21";
import { IconlySharpBoldArrowLeft21 } from "../../icons/IconlySharpBoldArrowLeft21";
import phone from "../../images/Group 23.png";
import phonenfc from "../../images/phone-nfc.png";
import phonesmooth from "../../images/phone-smooth.png";
import phoneaccount from "../../images/phone-account.png";
import phoneupdated from "../../images/phone-updated-go.png";
import phoneqr from "../../images/phone-qr.png";
import "./style.scss"

export const Slider2 = () => {

    const sliderRef = useRef<Slider | null>(null)

    const settings = {
        dots: true,
        infinite: true,
        //speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 80000,
      };
    const data = [
        
        {
            title: 'Smooth Financial Operations',
            img: phonesmooth,
            text: "Financial management is a breeze with our device. Whether merchants are settling payments with suppliers, obtaining a micro-loan or subscribing to an investment opportunities, every operation is smooth and intuitive. It's more than just a transaction; it's about empowering merchants every step of the way."
        },
        {
            title: 'Instant NFC Payments',
            img: phonenfc,
            text: "Just a simple tap, and payments are processed instantly.  With same-day settlements, merchants can access their earnings faster than ever. Use other payments types likes USSD, Bank-to-Bank transfe and e-Naira payments. It's not just about speed; it's about making each one seamless and efficient."
        },
        {
            title: 'Cutting-Edge QR Code Transactions',
            img: phoneqr,
            text: "Step into the new age of payments with our QR code features. A quick scan is all it takes to process merchant transactions like supplier, bill and donation payments; bridging the gap between traditional and modern payment methods. It's not just a scan; it's a revolution in your pocket."
        },
        {
            title: 'Swift Access to Merchant Accounts',
            img: phoneaccount,
            text: "With our device, conducting Tier 1, 2 & 3 Know-Your-Customer process become simpler. Micro-merchants can be handheld through the various KYC stages, resolving issues and allowing them to view sales and payment reports in your application."
        },
        {
            title: 'Stay Updated On-the-Go',
            img: phoneupdated,
            text: 'Our compact device doesn\'t compromise on connectivity. Merchants can dive into the vast digital realm, from catching the latest news to engaging on social media platforms. It\'s about staying connected with your customers, suppliers and your finance, no matter where you are.'
        },
    ]
    return (
        <div className="marchant-slider">
            <div className="iconly-sharp-bold-wrapper sm-hide" onClick={() => sliderRef?.current?.slickPrev()} >
                <IconlySharpBoldArrowLeft21 className="icon-instance-node" color="#0151FD" />
            </div>

            <Slider  {...settings} className="slider-carousel" ref={sliderRef}>
                {
                    data.map((item, index) => (
                        <div className="frame-14" key={index}>

                            <img
                                className="image-2"
                                alt="services"
                                src={item.img}
                            />

                            <div className="frame-15">
                                <div className="title-6">{item.title}</div>
                                <p className="title-7">
                                    {item.text}
                                </p>
                            </div>
                        </div>
                    ))
                }


            </Slider>

            <div className="iconly-sharp-bold-wrapper sm-hide" onClick={() => sliderRef?.current?.slickNext()}>
                <IconlySharpBoldArrowRight21 className="icon-instance-node" color="#0151FD" />
            </div>
            <div className="moble-button">
                <div className="iconly-sharp-bold-wrapper" onClick={() => sliderRef?.current?.slickPrev()} >
                    <IconlySharpBoldArrowLeft21 className="icon-instance-node" color="#0151FD" />
                </div>
                <div className="iconly-sharp-bold-wrapper" onClick={() => sliderRef?.current?.slickNext()}>
                    <IconlySharpBoldArrowRight21 className="icon-instance-node" color="#0151FD" />
                </div>
            </div>
        </div>
    );
}
