import React, { useRef } from "react";
import Slider from "react-slick";
import './style.scss'
import phones1 from "../../images/phones1.png"
import phones2 from "../../images/phone2.png"
import phones4 from "../../images/phone4.png"

export const SliderCar = () => {
  const sliderRef = useRef<Slider | null>(null)
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  }
  return (
    <div className="slider1">

      <Slider  {...settings} className="slider-carousel" ref={sliderRef}>

        <div className="frame-14">
          <div className="image-wrapper">
            <img
              className="image-2"
              alt="services"
              src={phones1}
            />
          </div>

        </div>
        <div className="frame-14">
          <div className="image-wrapper">
            <img
              className="image-2"
              alt="services"
              src={phones2}
            />
          </div>

        </div>
        <div className="frame-14">
          <div className="image-wrapper">
            <img
              className="image-2"
              alt="services"
              src={phones4}
            />
          </div>

        </div>
      </Slider>
    </div>
  );
}
