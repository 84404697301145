import React from "react";
import frameSlider1 from "../../images/frameSlider 1.png"
import frameSlider2 from "../../images/frameSlider 2.png"
import frameSlider3 from "../../images/frameSlider 3.png"
import frameSlider4 from "../../images/frameSlider 4.png"
import './style.scss'

export const ContactSlider = () => {

    const data = [
        {
            img: frameSlider1

        },
        {
            img: frameSlider2

        },
        {
            img: frameSlider3

        },
        {
            img: frameSlider4

        },
    ]
    return (
        <div className='images-slider'>
            <div className="images-slider-innner">

            {data.map((frame, index) => (
                <div key={index}>
                    <div className='testimony_box'  >
                        <img src={frame.img} alt="testimonal" />
                    </div>
                </div>
            ))}
            </div>
        </div>


    );


}