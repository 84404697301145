import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { LandingPagev3 } from "../screens/LandingPagev3";
import { ContactSales } from "../screens/CoontactSales";
import { AboutUs } from "../screens/AboutUs";
import { PrivacyPolicy } from "../screens/PrivacyPolicy";
import { TermsConditions } from "../screens/TermsConditions";
import { NotFound } from "../screens/404Page";

const AppRouters = createBrowserRouter([
   
    {
        path: "/",
        element: <LandingPagev3/>,
    },
    {
        path: "/contact-sales",
        element: <ContactSales />,
    },
    {
        path: "/about-us",
        element: <AboutUs />,
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
    },
    {
        path: "/terms-conditions",
        element: <TermsConditions />,
    },
    {
        path: "/*",
        element: <NotFound/>,
    },
]);

export default AppRouters