import "./style.scss"
import NotFound404 from "../../images/Notfound.png"
import { Link } from "react-router-dom"
import { Navbar } from "../../components/Navbar"



export const NotFound = (): JSX.Element => {
    return (
        <div>
             <Navbar
                className="navbar-instance"
                property1="contact-sales"
                regularButtonsButtonTypePrimaryClassName="design-component-instance-node-2"
                to="/"
            />
            <div className="notfound max-width">
                <div className="image">
                    <img src={NotFound404} alt="" />
                </div>
                <div className=" text">
                    <h1><strong>Oops... </strong> Page not found</h1>
                    <Link to="/">
                    <button className="notfound-btn">
                        Go Back Home
                    </button>
                    </Link>
                   
                </div>

                <div>

                </div>
            </div>

        </div>
    )
}