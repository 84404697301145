import React from "react";

interface Props {
  className: any;
}

export const RemixIconsLineSystemArrowDownSLine1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`remix-icons-line-system-arrow-down-s-line-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_493_2535)">
        <path
          className="path"
          d="M12.1579 13.1722L17.1079 8.22217L18.5219 9.63617L12.1579 16.0002L5.7939 9.63617L7.2079 8.22217L12.1579 13.1722Z"
          fill="#1D2433"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip_path" id="clip0_493_2535">
          <rect className="rect" fill="white" height="24" transform="translate(0.157898)" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
