import axios from "axios"
// import cookie from "js-cookie"



const bearerToken = '';

export async function postCall(endpoint: string, data: any, headers?: any) {
    // const bearerToken = cookie.get('auth');

    return axios({
        method: 'POST',
        url: endpoint,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`,
            ...headers,
        },
        data,
    })
        .then((response: any) => response)
        .catch((error: any) => {
            return error.response;
        });
}

export async function getCall(endpoint: string, headers?: any) {
    return axios({
        method: 'GET',
        url: endpoint,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`,
            ...headers,
        },
    })
        .then((response: any) => response)
        .catch((error: any) => {
            return error.response;
        });
}

export async function patchCall(endpoint: string, data: any, headers: any) {
    return axios({
        method: 'PATCH',
        url: endpoint,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`,
            ...headers,
        },
        data,
    })
        .then((response: any) => response)
        .catch((error: any) => {
            return error.response
        });
}

export async function putCall(endpoint: string, data: any, headers: any) {
    return axios({
        method: 'PUT',
        url: endpoint,
        headers: {
            ...headers,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`,
        },
        data,
    })
        .then((response: any) => response)
        .catch((error: any) => {
            return error.response;
        });
}