import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { RemixIconsLineSystemArrowDownSLine1 } from "../../icons/RemixIconsLineSystemArrowDownSLine1";
import { RegularButtons } from "../RegularButtons";
import Logo from "../../images/full-logo.png"
import "./style.scss";

interface Props {
  property1: "contact-sales" | "default";
  className: any;
  regularButtonsButtonTypePrimaryClassName: any;
  to: string;
}

export const Navbar = ({ property1, className, regularButtonsButtonTypePrimaryClassName, to }: Props): JSX.Element => {
  return (


    <div className={`navbar  ${className} max-width`}>
      <Link to={to}>
        <img
          className="title"
          alt="Title"
          src={Logo}
        />
      </Link>
      {property1 === "default" && (
        <>
          <div className="frame-2">
            <div className="title-2">About Us</div>
            <div className="title-2">Features</div>
            <div className="title-2">Pricing</div>
            <div className="frame-3">
              <div className="title-2">Contact Us</div>
              <RemixIconsLineSystemArrowDownSLine1 className="remix-icons-line" />
            </div>
          </div>
          <div className="frame-4">
            <RegularButtons
              buttonLabelClassName="instance-node"
              buttonType="secondary"
              className="regular-buttons-instance"
              rightIcon={false}
              size="normal"
              state="default"
              text="Log In"
            />
            <RegularButtons
              buttonLabelClassName="regular-buttons-3"
              buttonType="primary"
              className="regular-buttons-2"
              rightIcon={false}
              size="normal"
              state="default"
              text="Sign Up"
            />
          </div>
        </>
      )}

      {property1 === "contact-sales" && (

        <Link to={'/contact-sales'}>
          <button className="getInTouch-btn">Get In Touch</button>
        </Link>
      )}
    </div>

  );
};

Navbar.propTypes = {
  property1: PropTypes.oneOf(["contact-sales", "default"]),
  to: PropTypes.string,
};
