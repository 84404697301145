import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const IconlySharpBoldArrowRight21 = ({ color = "#0151FD", className }: Props): JSX.Element => {
  return (
    <svg
      className={`iconly-sharp-bold-arrow-right-2-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M7.08329 17.0116L5.90495 15.8333L11.7383 9.99995L5.90495 4.16661L7.08329 2.98828L14.095 9.99995L7.08329 17.0116Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

IconlySharpBoldArrowRight21.propTypes = {
  color: PropTypes.string,
};
