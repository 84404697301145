import { Footer } from "../../components/Footer"
import { Navbar } from "../../components/Navbar"
import "./style.scss"



export const PrivacyPolicy = (): JSX.Element => {

    return (

        <div>
            <Navbar
                className="navbar-instance"
                property1="contact-sales"
                regularButtonsButtonTypePrimaryClassName="design-component-instance-node-2"
                to="/"
            />
            <div className="privacy max-width">
                <h1 >Privacy Policy</h1>
                <h1 className="Title">Last Updated: Aug 1, 2023</h1>
                <h3 className="Title">Please read these terms and conditions carefully before using Our Service.</h3>
                <ol>
                    <li className="Title top"><h3>Introduction</h3></li>
                    <p>Welcome to Devos Inc. This Privacy Policy is designed to help you understand how we collect, use, and safeguard the information you provide to us and to assist you in making informed decisions when using our website and our services.</p>

                    <li className="Title"><h3> Information We Collect</h3></li>
                    <h3 className="Title sub">a. Personal Data</h3>
                    <p>While using our website, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:</p>
                    <ul className="ul">
                        <li>Name</li>
                        <li>Email address</li>
                        <li>Phone number</li>
                        <li>Job Title</li>
                        <li>Business name</li>
                        <li>Address, State, Province, ZIP/Postal code, City</li>
                    </ul>
                    <h3 className="Title"><h3>b. Usage Data</h3></h3>
                        <p>We may also collect information on how the website is accessed and used. This Usage Data may include information such as your computer's IP address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.</p>
                        <h3 className="Title"><h3>c. Tracking & Cookies Data</h3></h3>
                            <p>We use cookies and similar tracking technologies to track the activity on our website and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. They are sent to your browser from a website and stored on your device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our website.</p>

                            <li className="Title"><h3>Use of Data</h3></li>
                            <p>Devos Inc uses the collected data for various purposes:</p>

                            <div className="ul">
                                <ul>
                                    <li>To provide and maintain our website</li>
                                    <li>To notify you about changes to our website or any products or services we offer or provide through it</li>
                                    <li>To allow you to participate in interactive features of our website</li>
                                    <li>To provide customer support</li>
                                    <li>To gather analysis or valuable information so that we can improve our website</li>
                                    <li>To monitor the usage of our website</li>
                                    <li>To detect, prevent, and address technical issues</li>
                                    <li>To provide you with news, special offers, and general information about other goods, services, and events which we offer</li>
                                </ul>
                            </div>

                            <li className="Title"><h3>Legal Basis for Processing Personal Data under GDPR</h3></li>
                            <p>If you are from the European Economic Area (EEA), Devos Inc's legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>
                            <li className="Title"><h3>Retention of Data</h3></li>
                            <p>Devos Inc will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.</p>
                            <li className="Title"><h3> Transfer of Data</h3></li>
                            <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
                            <li className="Title"><h3>Disclosure of Data</h3></li>
                            <p>We may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                            <div className="ul">
                                <ul>
                                    <li>Comply with a legal obligation</li>
                                    <li>Protect and defend the rights or property of Devos Inc</li>
                                    <li>Prevent or investigate possible wrongdoing in connection with the website</li>
                                    <li>Protect the personal safety of users of the website or the public</li>
                                    <li>Protect against legal liability</li>
                                </ul>
                            </div>

                            <li className="Title"><h3>Your Data Protection Rights under GDPR </h3></li>
                            <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Devos Inc aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>

                            <li className="Title"><h3> Contact Us</h3></li>
                            <p>By email: legal@getdevos.com</p>
                            <p>By visiting this page on our website: <a href="https://getdevos.com/contact-sales">https://getdevos.com/contact-sales</a></p>
                            <p>By mail: 8 the Green Ste A, Kent, 19901 DE, USA</p>

                            <li className="Title"><h3>Changes to This Privacy Policy</h3></li>
                            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

                        </ol>

                    </div>
                    <Footer />
            </div>
            )

}