import PropTypes from "prop-types";
import React from "react";
import { IconlyRegular } from "../IconlyRegular";
import "./style.scss";

interface Props {
  rightIcon: boolean;
  text: string;
  leftIcon?: boolean;
  buttonType: "tertiary" | "secondary" | "elevated" | "primary" | "error";
  state: "default" | "pressed" | "focused" | "hover" | "disabled";
  size: "small" | "normal";
  className: any;
  buttonLabelClassName: any;
}

export const RegularButtons = ({
  rightIcon = true,
  text = "Button Label",
  buttonType,
  state,
  size,
  className,
  buttonLabelClassName,
}: Props): JSX.Element => {
  return (
    <button className={`regular-buttons ${buttonType} ${state} ${size} ${className}`}>
      {["default", "disabled", "hover", "pressed"].includes(state) && (
        <>
          <>
            {rightIcon && (
              <IconlyRegular
                className="iconly-regular-instance"
                home={
                  (buttonType === "elevated" && size === "small" && state === "default") ||
                  (buttonType === "elevated" && size === "small" && state === "hover") ||
                  (buttonType === "elevated" && size === "small" && state === "pressed") ||
                  (buttonType === "error" && size === "small" && state === "default") ||
                  (buttonType === "error" && size === "small" && state === "hover") ||
                  (buttonType === "error" && size === "small" && state === "pressed") ||
                  (buttonType === "primary" && size === "small" && state === "default") ||
                  (buttonType === "primary" && size === "small" && state === "hover") ||
                  (buttonType === "primary" && size === "small" && state === "pressed")
                    ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-49@2x.png"
                    : size === "normal" && state === "default" && ["secondary", "tertiary"].includes(buttonType)
                    ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-44@2x.png"
                    : size === "small" && state === "default" && ["secondary", "tertiary"].includes(buttonType)
                    ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-43@2x.png"
                    : size === "normal" && state === "hover" && ["secondary", "tertiary"].includes(buttonType)
                    ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-32@2x.png"
                    : size === "small" && state === "hover" && ["secondary", "tertiary"].includes(buttonType)
                    ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-31@2x.png"
                    : state === "disabled" && size === "normal"
                    ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-18@2x.png"
                    : size === "small" && state === "disabled"
                    ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-17@2x.png"
                    : size === "normal" && state === "pressed" && ["secondary", "tertiary"].includes(buttonType)
                    ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-12@2x.png"
                    : size === "small" && state === "pressed" && ["secondary", "tertiary"].includes(buttonType)
                    ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-11@2x.png"
                    : "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-50@2x.png"
                }
                homeClassName="iconly-regular-outline-home"
              />
            )}
          </>
          <div className={`button-label ${buttonLabelClassName}`}>{text}</div>
        </>
      )}

      {state === "focused" && (
        <div className="div">
          {rightIcon && (
            <IconlyRegular
              className="iconly-regular-instance"
              home={
                size === "small" && ["elevated", "error", "primary"].includes(buttonType)
                  ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-49@2x.png"
                  : size === "normal" && ["secondary", "tertiary"].includes(buttonType)
                  ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-32@2x.png"
                  : size === "small" && ["secondary", "tertiary"].includes(buttonType)
                  ? "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-31@2x.png"
                  : "https://generation-sessions.s3.amazonaws.com/ab641fd308ff1431f8abb9da3df954cc/img/home-50@2x.png"
              }
              homeClassName="iconly-regular-outline-home"
            />
          )}

          <button className="text-wrapper">{text}</button>
        </div>
      )}
    </button>
  );
};

RegularButtons.propTypes = {
  rightIcon: PropTypes.bool,
  text: PropTypes.string,
  leftIcon: PropTypes.bool,
  buttonType: PropTypes.oneOf(["tertiary", "secondary", "elevated", "primary", "error"]),
  state: PropTypes.oneOf(["default", "pressed", "focused", "hover", "disabled"]),
  size: PropTypes.oneOf(["small", "normal"]),
};
