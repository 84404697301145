import { object, number, string, ObjectSchema } from 'yup';

export interface PersonDetails {
    firstName: string;
    lastName: string;
    businessEmail:string;
    businessName:string;
    businessPhone:number | string;
    jobTitle :string;
    comment?:string;
  }
  
  export const UserValidation: ObjectSchema<PersonDetails> = object({
    firstName: string().required("First name is required!"),
    lastName: string().required("Last name is required!"),
    businessEmail: string().email().required("Email is required!"),
    businessName:string().min(2, "Too short!").max(150, "Too Long!").required("Business Name is required!"),
    businessPhone:number().required("Phone number is required!"),
    // .matches(/^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g,
    //   "Invalid phone number"
    // ),
    jobTitle:string().required("Job Title is required!"),
    comment:string(),

  });

  export interface NewsletterDetails{
    email: string;
  }

  export const EmailValidation:ObjectSchema<NewsletterDetails> = object({
    email:string().email().required("Email is required!")
   });